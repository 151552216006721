<template>
  <div
    ref="sectionRef"
    class="mb-14 pt-14 lg:mb-30 lg:pt-30 bg-[linear-gradient(to_bottom,_theme(colors.white2)_50%,_#fff_50%);]"
  >
    <div class="container text-center">
      <Heading :content="data.title" />
      <div
        v-if="data.html"
        class="mx-auto mb-8 text-lg lg:mb-10 lg:text-xl"
        v-html="data.html"
      ></div>
      <div class="mx-auto mt-10 max-w-3xl">
        <LazyVideoEmbed v-if="isSectionVisible" :data="data.video" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)

const { stop } = useIntersectionObserver(sectionRef, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting) {
    isSectionVisible.value = true
  }
})

onUnmounted(() => {
  stop()
})
</script>
